// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-changelog-tsx": () => import("./../src/templates/changelog.tsx" /* webpackChunkName: "component---src-templates-changelog-tsx" */)
}

